import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../contexts/MainContext";
// eslint-disable-next-line
import { Link } from "react-router-dom";
// import { FormPago } from './FormPago'
import axios from "axios";

export const Inicio = ({ history }) => {
  const { setHistoria } = useContext(MainContext);
  const [selFiles, setSelFiles] = useState([]);

  useEffect(() => {
    setHistoria(history);
    return () => {};
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const onFileChange = (event) => {
    // Update the state
    setSelFiles(event.target.files);
  };

  // eslint-disable-next-line
  const onFetch = async () => {
    const formData = new FormData();

    formData.append("address", "0xbpoo12i41i12k62j6m236m23m");
    formData.append("email", "kevinoxxd@gmail.com");

    if (selFiles.length < 1) {
      return 0;
    }

    for (let index = 0; index < selFiles.length; index++) {
      const element = selFiles[index];
      console.log(element.name);

      formData.append("voucher", element, element.name);
    }

    // Request made to the backend api
    // Send formData object
    await axios
      .post("http://webknox.ml:5500/upload_file", formData, {
        headers: {
          Accept: "*/*",
          Action: "create_voucher",
          // "Content-Type": "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => console.log("data: ", data))
      .catch((err) => console.log("error: ", err));

    // const mdata = {
    //   method: "POST",
    //   headers: {
    //     // "Origin": "http://webknox.ml:3000",
    //     "Accept": "*/*",
    //     "Action": "create_voucher",
    //     "Connection": "keep-alive",
    //     // "Content-Type": "application/json",
    //     'Content-Type': 'multipart/form-data'
    //   },
    //   file: selFile,
    //   body: JSON.stringify({
    //     address: '0xbpoo12i41i12k62j6m236m23m',
    //     email: 'kevinoxxd@gmail.com',
    //   })
    // }

    // fetch( 'http://webknox.ml:5500/create_preference', mdata )
    // .then( resp => { return resp.json() })
    // .then( data => console.log( data ))
    // .catch( err => console.log( err ))
  };

  return (
    <div>
      <Link to="/productos" className="btn btn-primary mx-auto">
        {" "}
        Llévame a los Productos{" "}
      </Link>
      {/* <FormPago /> */}
      {/* <form encType="multipart/form-data" onSubmit={ onFetch }> */}
      {/* <input type="file" name="voucher" onChange={ onFileChange } /> */}
      {/* <button className="btn btn-primary mx-auto" onClick={ onFetch }> Llévame a los Productos </button> */}
      {/* </form> */}
    </div>
  );
};
