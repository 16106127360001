import React, { useContext, useEffect, useState } from 'react'
import { alpha, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'
import Badge from '@material-ui/core/Badge'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import SearchIcon from '@material-ui/icons/Search'
import AccountCircle from '@material-ui/icons/AccountCircle'
import MailIcon from '@material-ui/icons/Mail'
import NotificationsIcon from '@material-ui/icons/Notifications'
import MoreIcon from '@material-ui/icons/MoreVert'
import logo_blanco from '../images/Luunox_blanco_(300x263).png'
import { ShoppingCart } from '@material-ui/icons'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import Chip from '@material-ui/core/Chip'
import List from '@material-ui/core/List'
import KnoxDialog from './KnoxDialog'
import { Icon } from '@material-ui/core'
import romperArray from './RomperArray'
import accounting from 'accounting'
// import PaypalCheckoutButton from './PaypalCheckoutButton';
// import pay_pal from '../images/paypal.jpg'
import { MainContext } from '../contexts/MainContext'
import { FormPago } from './FormPago'
import { Button, Modal } from 'antd'
// import { Redirect } from 'react-router-dom'
// import IOSSwitch from './IOSSwitch'
// import { withStyles } from '@material-ui/core/styles'
// import Switch from '@material-ui/core/Switch'

const useStyles = makeStyles((theme) => ({
  noPadding: {
    margin: 0,
    paddingLeft: 0,
    paddingTop: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  appBar: {
    backgroundColor: 'rgba(10,70,90,1)',
    height: 8 + 'vh',
    position: 'fixed',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  image: {
    height: 3.5 + 'rem',
  },
  userText: {
    margin: 'auto'
  },
  grow: {
    flexGrow: 1, // Este es un separador grande
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(4),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const logIn = () => {

}
const logOut = () => {

}

export default function Navbar() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null)
  const [selectedValue, setSelectedValue] = useState('logOut')
  // eslint-disable-next-line
  const [order, setOrder] = useState({})
  const [adicional, setAdicional] = useState(0)
  const [priceUSD, setPriceUSD] = useState(0)
  const {users, globalData, shopCart, setShopCart, mailB, notifyB, shopCartB, setShopCartBadge, modal, setModal, historia} = useContext( MainContext )
  const [ fundata, setFundata ] = useState({ visible: modal, loading: true })
  
  let appName = 'Luunox'
  let costoTotal = 0

  useEffect(() => {
    setPriceUSD(4.10)
    return () => {
      
    }
  }, [])

  shopCart.map(dat =>
    costoTotal += dat.precio
  )

  const CargarPago = () => {

    const showModal = () => {
      // setFundata({ visible: true })
      setModal( true )
    }
  
    const handleOk = () => {
      setFundata({ ...fundata, loading: true })
      setTimeout(() => {
        setFundata({ loading: false, visible: false })
      }, 3000)
    }
    
    const handleCancel = () => {
      // setFundata({ ...fundata, visible: false })
      setModal( false )
    }
    
    // eslint-disable-next-line
    const customFooter = (
      <>
        <Button key="back" onClick={ handleCancel }>
          Return
        </Button>
        <Button key="submit" type="primary" loading={ fundata.loading } onClick={ handleOk }>
          Submit
        </Button>
        <Button
          key="link"
          href="https://google.com"
          type="primary"
          loading={ fundata.loading }
          onClick={ handleOk }
          >
          Search on Google
        </Button>
      </>
    )
    
    const total = costoTotal + adicional

    return (
      <>
        <button className="btn btn-primary mx-auto" onClick={ showModal }> Comprar </button>
        <Modal
          title="Title"
          footer={ null }
          // onOk={ handleOk }
          onCancel={ handleCancel }
          visible={ modal }
          // confirmLoading={ fundata.loading }
        >
          <FormPago total={ total.toString() } />
        </Modal>
      </>
    )
  }

  accounting.settings = {
    currency: {
      symbol : "S/",   // default currency symbol is '$'
      format: {
        pos : "%s %v",   // for positive values, eg. "$ 1.00" (required)
        neg : "%s -(%v)", // for negative values, eg. "$ -(1.00)" [optional]
        zero: "%s  --"  // for zero values, eg. "$  --" [optional]
      }, // controls output: %s = symbol, %v = value/number (can be object: see below)
      thousand: ",",  // thousands separator
      decimal : ".",  // decimal point separator
      precision : 2   // decimal places
    },
    number: {
      precision : 0,  // default precision on numbers is 0
      thousand: ",",
      decimal : "."
    }
  }

  useEffect(() => {
    let subtotal = 0
    shopCart.length > 0 ? setAdicional(1) : setAdicional(0)
    shopCart.map(dat => 
      subtotal += +( accounting.toFixed( dat.precio / priceUSD, 2 ) )
    )
    setOrder({
      cliente: '315456',
      moneda: 'PEN',
      total: accounting.toFixed(subtotal + adicional, 2),
      details: {
        subtotal: accounting.toFixed(subtotal, 2),
        impuesto: '0.25',
        envio: '0.25',
        seguro: '1.25',
        mano_obra: '0.25',
        descuento_envio: '-1.00',
      },
      descripcion: 'Compra en Luunox',
      nota_comprador: 'Contáctenos para cualquier duda',
      items: 
        shopCart.map( dat => (
          {
            sku: dat.id,
            name: dat.nombre,
            price: accounting.toFixed( dat.precio / priceUSD, 2 ),
            quantity: '1',
            currency: 'USD',
          }
        )),
    })
    
    return () => {
      
    }
  }, [shopCart, costoTotal, adicional, priceUSD])
  
  const handleAdicional = () => {
    
  }
  const handleLogo = () => {
    historia.push('/')
    // console.log( historia )
  }
  const handleDiagClickOpen = () => {
    setOpen(true)
  }
  const handleDiagClose = (value) => {
    if (value === 'addAccount' && value !== selectedValue) {
      logIn()
      setSelectedValue(value)
    } else if (value === 'logOut' && value !== selectedValue) {
      logOut()
      setSelectedValue(value)
    } else {
      setSelectedValue(value)
      setOpen(false)
    }
  }

  const renderAccounts = (
    <KnoxDialog array={users} selectedValue={selectedValue} open={open} onClose={handleDiagClose} />
  )

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null)
  }

  const handleShoppingCart = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseCart = () => {
    setAnchorEl(null)
  }

  const Cart = ({data}) => {
    const condicion = (e) => e.key === data.key;
    const currentIndex = shopCart.findIndex(condicion);
    const dataArr = romperArray(globalData);

    const handleDelete = () => {
      const newChecked = [...shopCart]
  
      if (currentIndex === -1) {
        newChecked.push(dataArr.find(condicion))
        setShopCartBadge(shopCartB + 1)
      } else {
        newChecked.splice(currentIndex, 1)
        setShopCartBadge(shopCartB - 1)
      }
  
      setShopCart(newChecked)
    }

    return (
    <Chip key={data.key} onDelete={handleDelete}
      label={
        <Typography style={{fontSize: 0.9 + 'rem'}}>
            { data.nombre } - { accounting.formatMoney( data.precio ) }
        </Typography>
      }
      avatar={<Avatar src={data.imagen}/>}
    />
    )
  }

  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = ( // Muestra menú en resolución movil
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="Mostrar correos" color="inherit">
          <Badge badgeContent={mailB} color="secondary">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Mensages</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label="Mostrar notificaciones" color="inherit">
          <Badge badgeContent={notifyB} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificaciones</p>
      </MenuItem>
      <MenuItem onClick={handleDiagClickOpen}>
        <IconButton
          aria-label="Cuenta del usuario"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Perfil</p>
      </MenuItem>
    </Menu>
    // </div>
  )

  return (
      <AppBar className={classes.appBar} position="fixed">
        <Toolbar>
        <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Abrir menú"
            onClick={ handleLogo }
          >
            <img className={classes.image} src={logo_blanco} alt='Luunox'/>
          </IconButton>
          <Typography className={classes.title} style={{ fontSize: 20 }} >
            {appName}
          </Typography>
          <div className={classes.grow} />
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Buscar…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'buscar' }}
            />
          </div>
          <div className={classes.sectionDesktop}> {/* Botones Desktop */}
            <IconButton aria-label="Mostrar correos" color="inherit">
              <Badge badgeContent={mailB} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label="Mostrar notificaciones" color="inherit">
              <Badge badgeContent={notifyB} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <IconButton
              aria-label="Cuenta del usuario"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleDiagClickOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
              <Typography noWrap className={classes.userText} style={{ fontSize: 18 }}>
                {selectedValue !== "logOut" ? selectedValue : 'Invitado'}
              </Typography>
          </div>
          <IconButton
            edge={false}
            aria-label="Carrito de compras"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleShoppingCart}
            color="inherit"
          >
            <Badge badgeContent={shopCartB} color="secondary">
              <ShoppingCart style={{fontSize: 1.3 + 'em', color: 'rgba(255,255,255,1)'}} />
            </Badge>
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClick={handleCloseCart}
          >
            <List dense={false} className={classes.noPadding}>
            {shopCart.map((dat, id) =>
              <ListItem key={id} style={{paddingTop: 0}}>
                <Cart data={dat} />
              </ListItem>
            )}
            <Divider />
            {
              adicional > 0 &&
              <ListItem>
                <Chip clickable onClick={handleAdicional}
                  label={
                  <Typography style={{fontSize: 0.9 + 'rem'}}>
                    Costos adicionales - { accounting.formatMoney(adicional) }
                  </Typography>
                  }
                  avatar={<Icon style={{fontSize: 1.5 + 'rem'}} >monetization_on</Icon>}
                />
              </ListItem>
            }
            <ListItem style={{display:'flex', justifyContent: 'center', paddingBottom: 0}}>
              <Typography style={{fontSize: 0.9 + 'rem'}}>
                Precio total - {accounting.formatMoney(costoTotal + adicional)}
              </Typography>
            </ListItem>
            <ListItem style={{paddingBottom: 0}}>
              {/* { costoTotal > 1 ? <PaypalCheckoutButton order={ order } /> : <img alt="pay" src={pay_pal} /> } */}
              { costoTotal + adicional > 1 ? <CargarPago /> : null }
            </ListItem>
            </List>
          </Menu>
          {/* <div style={{marginLeft: 1 + 'rem'}}>
            <FormControlLabel
               control={<IOSSwitch
                        checked={handleValue('dark')}
                        onChange={handleToggle('dark')}
                        name="DarkMode" />}
               label="Modo Noche"
            />
          </div> */}
          <div className={classes.sectionMobile}>
            <IconButton
              edge="end"
              aria-label="Mostrar más"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
          {renderAccounts}
        </Toolbar>
        {renderMobileMenu}
      </AppBar>
  )
}
