import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import accounting from 'accounting';
import parse from 'html-react-parser';
import romperArray from './RomperArray';
import { Icon } from '@material-ui/core';
import { MainContext } from '../contexts/MainContext';
import { format } from 'date-fns'
import { es } from 'date-fns/locale';

const useStyles = makeStyles((theme) => ({
  materialIcons: {
    "font-family": 'Material Icons',
    "font-weight": 'normal',
    "font-style": 'normal',
    "font-size": 24 + 'px',  /* Preferred icon size */
    "display": 'inline-block',
    "line-height": 1,
    "text-transform": 'none',
    "letter-spacing": 'normal',
    "word-wrap": 'normal',
    "white-space": 'nowrap',
    "direction": 'ltr',
  
    /* Support for all WebKit browsers. */
    "-webkit-font-smoothing": 'antialiased',

    /* Support for Safari and Chrome. */
    "text-rendering": 'optimizeLegibility',
  
    /* Support for Firefox. */
    "-moz-osx-font-smoothing": 'grayscale',
  
    /* Support for IE. */
    "font-feature-settings": 'liga',
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  card: {
    opacity: 1,
    backgroundColor: 'rgba(10,70,90,0.7)',
    "&:hover, &:focus": {
      backgroundColor: 'rgba(40,70,120,0.7)',
      opacity: 1,
    },
  },
  title: {
    fontSize: 15 + 'px',
    fontWeight: 600,
  }, 
  date: {
    fontSize: 13.5 + 'px',
    fontWeight: 600,
  }, 
  price: {
    marginTop: 0.5 + 'rem',
    fontSize: 18 + 'px',
    fontWeight: 400,
  },
  media: {
    height: 12 + 'rem', // tamaño del contenedor
    objectFit: 'contain', // ajusta la imagen al tamaño del contenedor
  },
  div_describe: {
    lineHeight: 'normal',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  full_describe: {
    height: 10 + 'rem',
    lineHeight: 10 + 'rem',
    textAlign: 'center',
  },
  mini_describe: {
    height: 10 + 'rem',
    lineHeight: 10 + 'rem',
    textAlign: 'center',
  },
  grow: {
    flexGrow: 1, // Este es un separador grande
  },
  divAction: {
    width: '99.5%',
    lineHeight: 'normal',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  actions: {
    // backgroundColor: 'black',
    // height: '100%',
    // minWidth: 1 + 'rem',
    // width: '98%',
    // overflow: 'hidden',
    // background: 'black',
    // whitespace: 'wrap',
    // position: 'absolute',
    // bottom: 0,
    // verticalAlign: 'bottom',
    // alignSelf: 'flex-end',
  },
  addToCart: {
    fontSize: 1.5 + 'rem'
  },
  valora: {
    fontSize: 1.2 + 'rem',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    color: 'white',
    backgroundColor: red[500],
  },
}));

export default function Producto({ id, data }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { globalData, shopCart, setShopCart, shopCartB, setShopCartBadge } = useContext( MainContext )
  const today = format(new Date(), "dd/MM/yyyy hh:mm bbbb", { awareOfUnicodeTokens: true, locale: es})
  // const today = format(new Date(), "dd 'de' MMMM 'del' yyyy hh:mm bbbb", { awareOfUnicodeTokens: true, locale: es})

  const img_product = data.imagen;
  const img_title = 'Unos pantalones bonitos';
  const title = (<div className={classes.title}>{ data.nombre }</div>)
  const date = (<div className={classes.date}>{ today }</div>)
  const price = data.precio
  const full_describe = (<div className={classes.div_describe}>{parse(`${ data.descripcion_Larga }`)}</div>)
  const mini_describe = (<div className={classes.div_describe}>{parse(`${ data.descripcion_Corta }`)}</div>)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const condicion = (e) => e.key === data.key;
  const currentIndex = shopCart.findIndex(condicion);
  const dataArr = romperArray(globalData);
  const newChecked = [...shopCart];

  const handleAddToCart = (event) => {
    // const id = event.currentTarget.id;
    // console.log(id);
    if (currentIndex === -1) {
      newChecked.push(dataArr.find(condicion));
      setShopCartBadge(shopCartB + 1);
    } else {
      newChecked.splice(currentIndex, 1);
      setShopCartBadge(shopCartB - 1);
    }
    setShopCart(newChecked);
  };

  return (
    <Card className={classes.card} variant="outlined">
      <CardHeader
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            K
          </Avatar>
        }
        action={
          <Typography className={classes.price}>
            {accounting.formatMoney(price, "S/")}
          </Typography>
        }
        title={title}
        subheader={date}
      />
      <CardMedia
        component="img"
        className={classes.media}
        image={img_product}
        title={img_title}
      />
      <CardContent className={classes.noPadding}>
        <Typography className={classes.full_describe} variant="body2" component="div">
          {full_describe}
        </Typography>
      </CardContent>
      <center>
      <div className={classes.divAction}>
      <CardActions className={classes.actions}>
        <IconButton id={data.key} onClick={handleAddToCart} aria-label="Añadir al carrito">
          <Icon>
          { shopCart.findIndex((e) => e.key === data.key) === -1
          ? 'add_shopping_cart'
          : 'remove_shopping_cart'
          }
          </Icon>
        </IconButton>
        <div className={classes.grow} />
          {Array(5)
          .fill()
          .map((_, i) => (
            <div key={i}>&#11088;</div>
          ))}
        <div className={classes.grow} />
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Descripción"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      </div>
      </center>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{height: '100%'}} className={classes.noPadding}>
          <Typography className={classes.mini_describe} variant="body2" component="div">
            {mini_describe}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
