import React, { useContext, useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Producto from './Producto'
import romperArray from './RomperArray'
import { MainContext } from '../contexts/MainContext'

export default function Productos({ history }) {
  const { globalData, setHistoria } = useContext( MainContext )
  const [ data, setData ] = useState([])

  useEffect(() => {
    setHistoria( history )
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setData( romperArray( globalData ) )
  }, [ globalData ])

  return (
    <Grid
      className="pb-2 pb-sm-0"
      container spacing={1}
      direction="row"
      alignItems="stretch"
    >
      {data.map((dat, id) => (
        <Grid item key={'grid' + id} xs={12} sm={6} md={4} lg={3}>
          <Producto key={dat.id} id={dat.id} data={dat} />
        </Grid>
      ))}
    </Grid>
  )
}
