import React, { useContext, useEffect } from 'react'
import { Form, Tooltip, Button, Typography } from 'antd'
import 'antd/dist/antd.css'
import { MainContext } from '../contexts/MainContext'

const backend = 'https://backev.netlify.app/.netlify/functions/server/create_preference'
// const backend = 'http://localhost:8000/.netlify/functions/server/create_preference'

export const FormPago = ({ total = "2", descripcion = "Luunox" }) => {
  const { historia, setModal } = useContext( MainContext )

  const scriptLoaded = ( total, descripcion ) => {
    const mp = new window.MercadoPago('APP_USR-58ab45dc-8082-493e-87b1-48fc8e7d5c3b')
  
    const cardForm = mp.cardForm({
      amount: total,
      autoMount: true,
      form: {
        id: "form-checkout",
        issuer: { id: "form-checkout__issuer", placeholder: "Emisor", },
        securityCode: { id: "form-checkout__securityCode", placeholder: "CVV", },
        installments: { id: "form-checkout__installments", placeholder: "Cuotas", },
        cardholderEmail: { id: "form-checkout__cardholderEmail", placeholder: "E-mail", },
        cardExpirationYear: { id: "form-checkout__cardExpirationYear", placeholder: "Año", },
        cardNumber: { id: "form-checkout__cardNumber", placeholder: "Número de la tarjeta", },
        cardExpirationMonth: { id: "form-checkout__cardExpirationMonth", placeholder: "Mes", },
        cardholderName: { id: "form-checkout__cardholderName", placeholder: "Titular de la tarjeta", },
        identificationType: { id: "form-checkout__identificationType", placeholder: "Tipo de documento", },
        identificationNumber: { id: "form-checkout__identificationNumber", placeholder: "Número de documento", },
      },
      callbacks: {
        onFormMounted: error => {
          if (error) return console.warn("Form Mounted handling error: ", error)
          // console.log("Form mounted")
        },
        onSubmit: event => {
          event.preventDefault()
          
          const {
            paymentMethodId: payment_method_id,
            securityCode: security_code_id,
            cardholderEmail: email,
            issuerId: issuer_id,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = cardForm.getCardFormData()
          
          fetch(backend, {
            method: "POST",
            headers: {
              "Accept": "*/*",
              "Connection": "keep-alive",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: descripcion,
              security_code_id,
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            }),
          })
          .then(response => {
            return response.json()
          })
          .then(result => {
            // console.log( result )
            if ( result.status === 'approved' ) {
              alert('Pago con éxito')
              historia.push('/productos')
            } else {
              alert('Hubo un error')
              setModal(false)
              historia.push('/productos')
            }
            // document.getElementById("payment-status").innerText = result.status
            // document.getElementById("payment-detail").innerText = result.message
            // $('.container__payment').fadeOut(500)
            // setTimeout(() => { $('.container__result').show(500).fadeIn(); }, 500)
          })
          .catch(error => {
              alert("Unexpected error\n"+JSON.stringify(error))
          })
        },
        onFetching: (resource) => {
          // console.log("Fetching resource: ", resource)
          // Animate progress bar
          const progressBar = document.querySelector(".progress-bar")
          progressBar.removeAttribute("value")
          return () => {
            progressBar.setAttribute("value", "0")
          }
        },
      },
    })
  }

  useEffect(() => {
    const script = document.createElement('script')
    const url = 'https://sdk.mercadopago.com/js/v2'
  
    script.src = url
    script.async = true
    script.onload = () => scriptLoaded( total, descripcion )

    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  // eslint-disable-next-line
  }, [ ])

  const onFinish = values => {
    // console.log('Received values of form: ', values)
  }
  
  return (
    <div>
      <Form id="form-checkout" name="complex-form" onFinish={ onFinish } labelCol={{ span: 4 }} wrapperCol={{ span: 24 }} style={{ width: '100%' }} noValidate>

          <Form.Item label="" style={{ marginBottom: 0 }}>
            <Form.Item name="tarjeta" className="pe-1" style={{ display: 'inline-block', width: '38%' }} rules={[{ required: true, message: 'Ingrese una tarjeta' }]}>
              <input className="form-control" type="text" name="cardNumber" id="form-checkout__cardNumber" value="" />
            </Form.Item>
            <Form.Item name="mes" className="pe-1" style={{ display: 'inline-block', width: '13%' }} rules={[{ required: true, message: 'Ingrese el Mes' }]}>
              <input className="form-control text-center" type="text" name="cardExpirationMonth" id="form-checkout__cardExpirationMonth" />
            </Form.Item>
            <Form.Item name="año" className="pe-1" style={{ display: 'inline-block', width: '14%' }} rules={[{ required: true, message: 'Ingrese el Año' }]}>
              <input className="form-control text-center" type="text" name="cardExpirationYear" id="form-checkout__cardExpirationYear" />
            </Form.Item>
            <Form.Item name="cvv" className="pe-1" style={{ display: 'inline-block', width: '17.5%' }} rules={[{ required: true, message: 'Ingrese el CVV' }]}>
              <input className="form-control text-center" type="text" name="securityCode" id="form-checkout__securityCode" />
            </Form.Item>
            <Tooltip title="Useful information">
              <Typography.Link href="#API" className="ps-1">Que es cvv?</Typography.Link>
            </Tooltip>
          </Form.Item>

          {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
 
          <Form.Item label="" style={{ marginBottom: 0 }}>
            <Form.Item name="titular" className="pe-1" rules={[{ required: true, message: 'Nombre del titular' }]} style={{ display: 'inline-block', width: '50%' }}>
              <input className="form-control" type="text" name="cardholderName" id="form-checkout__cardholderName" />
            </Form.Item>
            <Form.Item name="email" className="pe-1" rules={[{ required: true, message: 'E-mail del titular' }]} style={{ display: 'inline-block', width: '50%' }}>
              <input className="form-control" type="text" name="cardholderEmail" id="form-checkout__cardholderEmail" />
            </Form.Item>
          </Form.Item>
          <Form.Item label="" style={{ marginBottom: 0 }}>
            <Form.Item name="documento" className="pe-1" style={{ display: 'inline-block', width: '50%' }} rules={[{ required: false, message: 'Seleccione un tipo' }]}>
              <select className="form-select" name="identificationType" id="form-checkout__identificationType"></select>
            </Form.Item>
            <Form.Item name="numeroDoc" className="pe-1" style={{ display: 'inline-block', width: '50%' }} rules={[{ required: true, message: 'Ingrese su documento' }]}>
              <input className="form-control" type="text" name="identificationNumber" id="form-checkout__identificationNumber" />
            </Form.Item>
          </Form.Item>

          {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          
          <Form.Item label="" style={{ marginBottom: 0 }}>
            <Form.Item name="emisor" className="pe-1" style={{ display: 'inline-block', width: 'calc(40% - 0px)' }} rules={[{ required: false, message: 'Seleccione un emisor' }]}>
              <select className="form-select" name="issuer" id="form-checkout__issuer">
                <option> Emisor </option>
              </select>
            </Form.Item>
            <Form.Item name="cuotas" className="pe-1" style={{ display: 'inline-block', width: 'calc(60% - 0px)' }} rules={[{ required: false, message: 'Seleccione una cuota' }]}>
              <select className="form-select" name="installments" id="form-checkout__installments">
                <option> Emisor </option>
              </select>
            </Form.Item>
          </Form.Item>

          {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}

          <Form.Item className="mb-0" label="" colon={ false }>
            <Button className="w-100" type="primary" htmlType="submit" id="form-checkout__submit">
              Pagar
            </Button>
          </Form.Item>
          <Form.Item className="mb-0" label="">
            <div className="progress">
              <progress className="progress-bar progress-bar-striped progress-bar-animated" aria-valuemin="0" aria-valuemax="100" value="0"></progress>
            </div>
          </Form.Item>
        </Form>
    </div>
  )
}
