import PropTypes from 'prop-types';

const romperArray = (arrFeo) => {
  let dataArr = []
  // console.log(arrFeo);
  arrFeo.length > 0
  &&arrFeo.map((datx) => {
    const imgNo = 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/da/Imagen_no_disponible.svg/1024px-Imagen_no_disponible.svg.png'
    let newDataArr = [...dataArr, {
      id: datx.id,
      key: datx.sku ? datx.sku : datx.name,
      nombre: datx.name,
      precio: datx.price.raw,
      imagen: datx.media?.source
              ? datx.media.source
              : imgNo,
      descripcion_Corta: datx.description,
      descripcion_Larga: datx.description,
    }]
    
    // datx.variant_groups.length > 0
    // &&datx.variant_groups.map((daty) => {
    //   let newArr = [...newDataArr, {
    //     id: daty.id,
    //     key: daty.sku ? daty.sku : daty.name,
    //     nombre: daty.name,
    //     precio: daty.price.raw,
    //     imagen: daty.media.length > 0
    //             ? daty.media.source
    //             : imgNo,
    //     descripcion_Corta: daty.attributes
    //                       .find(att => att.name === 'mini_describe')
    //                       ? daty.attributes
    //                       .find(att => att.name === 'mini_describe').value
    //                       : curr.metaDescription['es-PE'],
    //     descripcion_Larga: daty.attributes
    //                       .find(att => att.name === 'full_describe')
    //                       ? daty.attributes
    //                       .find(att => att.name === 'full_describe').value
    //                       : curr.metaDescription['es-PE'],
    //   }]
    //   return newDataArr = [...newArr]
    // })
    return dataArr = [...newDataArr]
  })
  return dataArr
}

export default romperArray

romperArray.propTypes = {
  arrFeo: PropTypes.array.isRequired,
}

romperArray.defaultProps = {
  arrFeo: [],
}