import React, { useEffect, useState } from 'react'
import { MainContext } from './contexts/MainContext'
import { createTheme, ThemeProvider } from '@material-ui/core/styles'
import Productos from './components/Productos'
import { BrowserRouter as Router } from 'react-router-dom' // imbr
import { Route, Switch, Redirect } from 'react-router-dom' // imbrc
import { Inicio } from './components/Inicio'
import Navbar from './components/Navbar'

export default function App () {

  const [users, setUsers] = useState(['hola'])
  const [toggle, setToggle] = useState(['dark'])
  const [mailB, setMailBadge] = useState(0)
  const [notifyB, setNotifyBadge] = useState(0)
  const [shopCartB, setShopCartBadge] = useState(0)
  const [shopCart, setShopCart] = useState([])
  const [globalData, setglobalData] = useState([])
  const [addedToCart, setAddedToCart] = useState([])
  const [historia, setHistoria] = useState()
  const [modal, setModal] = useState(false)

  const handleToggle = (value) => () => {
    const currentIndex = toggle.indexOf(value)
    const newChecked = [...toggle]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setToggle(newChecked)
  }

  const handleValue = (value) => {

    if (toggle.indexOf(value) !== -1) {
      return true
    } else {
      return false
    }
  }

  const prefersDarkMode = handleValue('dark')

  const theme = React.useMemo(
    () =>
    createTheme({
      palette: {
        type: prefersDarkMode ? 'dark' : 'light',
      },
    }),
    [prefersDarkMode],
  );

  //   const getData = async() => {
  //     try {
  //       const request = {
  //         uri: '/knoxdevs/products',
  //         method: 'GET',
  //       }
  //       const resp = await ProductData().execute(request)
  //       const arra = resp.body.results
  //       return arra
  //     }
  //     catch (error) {
  //       console.error(error)
  //       return ['No existe']
  //     }
  //   }

  //   getData()
  //   .then( ret => setglobalData( ret ) )
  //   .catch( err => console.log( err ) )

  const getData = async() => {
    const propers = {
      method: "GET",
      headers: {
        "X-Authorization": "sk_31123f9e1940d9f20ef787ee10c5daeec570f70805974",
      },
    }
    
    const resp = await fetch("https://api.chec.io/v1/products", propers)
    const { data } = await resp.json()
    return data
  }

  const getUsers = async() => {
    const resp = await fetch('https://fakestoreapi.com/users')
    const data = await resp.json()
    const user = data.map( us => {
      return us.email
    })
    return user
  }

  useEffect(() => {
    getUsers()
      .then( dat => setUsers( dat ) )
      .catch( setUsers([ 'kevinoxxd@knoxdevs.com', 'luunox@webknox.ml' ]) )
    getData()
      .then( ret => { console.log( ret ); setglobalData( ret ); })
      .catch( err => { console.log( err ); alert( err ); })
    return () => {
      
    }
  }, [])

  return (
    <MainContext.Provider value={{
      users, setUsers, globalData, setglobalData,
      shopCart, setShopCart, mailB, setMailBadge,
      notifyB, setNotifyBadge, shopCartB, setShopCartBadge,
      handleValue, handleToggle, addedToCart, setAddedToCart,
      toggle, setToggle, historia, setHistoria, modal, setModal
    }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Navbar />
          <div style={{ padding: '1vh', marginTop: '8vh', height: '90vh', overflowY: 'auto' }}>
            <Switch>
              <Route exact path="/productos" component={ Productos } />
              <Route exact path="/" component={ Inicio } />

              <Redirect to="/" />
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </MainContext.Provider>
  )
}
