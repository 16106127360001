import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import PersonIcon from '@material-ui/icons/Person';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  materialIcons: {
    "font-family": 'Material Icons',
    "font-weight": 'normal',
    "font-style": 'normal',
    "font-size": 24 + 'px',  /* Preferred icon size */
    "display": 'inline-block',
    "line-height": 1,
    "text-transform": 'none',
    "letter-spacing": 'normal',
    "word-wrap": 'normal',
    "white-space": 'nowrap',
    "direction": 'ltr',
  
    /* Support for all WebKit browsers. */
    "-webkit-font-smoothing": 'antialiased',

    /* Support for Safari and Chrome. */
    "text-rendering": 'optimizeLegibility',
  
    /* Support for Firefox. */
    "-moz-osx-font-smoothing": 'grayscale',
  
    /* Support for IE. */
    "font-feature-settings": 'liga',
  },
  avatar: {
    backgroundColor: theme.palette.text.primary,
  },
  title: {
    color: theme.palette.text.primary,
    // backgroundColor: theme.palette.background.default,
  },
  list: {
    // backgroundColor: theme.palette.background.default,
  },
}));

export default function KnoxDialog(props) {
  const classes = useStyles()
  const { title, array, type, icon, onClose, selectedValue, open } = props

  const handleClose = () => {
    onClose(selectedValue)
  }

  const handleListItemClick = (value) => {
    onClose(value)
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">
        <Typography className={classes.title} style={{ fontSize: 20 }}>
          {title}
        </Typography>
      </DialogTitle>
      <List className={classes.list}>
        {selectedValue !== "logOut" && (
          type === 'user'
          ?array.map((arr) => (
            <ListItem button onClick={() => handleListItemClick(arr)} key={arr}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={arr} />
            </ListItem>
          ))
          :array.map((arr) => (
            <ListItem button onClick={() => handleListItemClick(arr)} key={arr}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <span className={classes.materialIcons}>{icon}</span>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={arr} />
            </ListItem>
          ))
        )}

        <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
          <ListItemAvatar>
            <Avatar className={classes.avatar}>
              <span className={classes.materialIcons}>add</span>
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Agregar cuenta" />
        </ListItem>

        {selectedValue !== "logOut" && (
          <>
            <Divider />
            <ListItem autoFocus button onClick={() => handleListItemClick('logOut')}>
              <ListItemAvatar>
                <Avatar className={classes.avatar}>
                  <span className={classes.materialIcons}>logout</span>
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Cerrar sesión" />
            </ListItem>
          </>
        )}
      </List>
    </Dialog>
  )
}

KnoxDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  array: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
}

KnoxDialog.defaultProps = {
  title: 'Seleccione',
  icon: 'account_box',
  type: 'none',
  color1: 'rgba(255,255,255,1)',
  color2: 'rgba(0,70,70,1)',
  color3: 'rgba(0,85,85,1)',
}